/* Mixins */
.fc-config-component .card-title .jtl-button-component {
  vertical-align: middle;
  margin-left: 24px;
}
.fc-config-component .fee-input-wrapper {
  display: inline-block;
  margin-left: 32px;
}
.fc-config-component .fee-input-wrapper .fee-label {
  width: 80px;
  margin-right: 24px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.fc-config-component .fee-input-wrapper .fee-input {
  display: inline-block;
  width: 160px;
}
.fc-config-component .room-fee-wrapper .ant-col {
  max-width: 350px;
}
.fc-config-component .room-fee-wrapper .fee-input-wrapper {
  margin-bottom: 16px;
}